<template>
    <header>
        <h1 class="header_temp">Fill In The Venue Form</h1>
    </header>
</template>

<script>
export default {
    name: "Header",
    components: {

    },
}
</script>
<style scoped>
  header{
      margin-bottom: 20px;
  }
</style>
